










































































import { ref, computed, reactive, toRefs } from '@vue/composition-api';
import { useUserActions, useUserGetters } from '@/store';
import { ObjectId } from 'bson';
import axios from 'axios';
import Table from '@/components/molecules/MakeAdkTableView.vue';
import useTeamDoc from '@/composables/useTeamDoc';

export default {
  components: {
    Table
  },

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    updateDoc: {
      type: Function,
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    },
    isMonitor: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any, ctx: any) {
    const fileInput = ref(null);
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const { updateProgramState } = useUserActions(['updateProgramState']);
    const isLoading = ref(false);
    const isRemoving = ref(false);
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');
    const isFetching = ref(false);
    const teamDoc = ref();
    const { getTeamDoc }: any = useTeamDoc();
    const logs = ref([]);

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    async function loadInitialData() {
      isFetching.value = true;
      teamDoc.value = await getTeamDoc({
        program_id: ctx.root.$route.params.programId || programDoc.value?.data?.program_id,
        user_id: props.isMonitor ? props.studentId : user.value?._id.toString(),
        public_id: props?.value?.data?._id
      });
      const index = teamDoc.value.adks?.findIndex?.(a => a.name === props.adkType);
      logs.value = teamDoc.value.adks?.[index]?.logs || [];
      isFetching.value = false;
    }

    loadInitialData();

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const minLogs = computed(() => {
      return (
        adkData.value?.fields?.find(f => f.model === 'minLogs')?.setup?.minLogs ||
        adkData.value?.fields?.find(f => f.model === 'minLogs')?.preset?.minLogs
      );
    });

    const fieldIndex = computed(() => {
      if (props.adkType === 'deliverable')
        return adkData.value.fields.findIndex(f => f.name === 'm-deliverable-default');
      return adkData.value.fields.findIndex(f => f.name === 'm-project-implementation');
    });

    const fieldValue = computed({
      get: () => adkData.value.fields[fieldIndex.value].value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const state = reactive({
      logInput: '',
      logError: '',
      images: [] as File[],
      teamAdkIndex: -1,
      userId: null as null | ObjectId,
      studentAdkData: null as null | Record<string, any>,
      logMilestoneLoading: false,
      proof: []
    });

    state.userId = new ObjectId(
      user.value?._id.toString() || programDoc.value?.data?.participant_id.toString()
    );

    const logsLeft = computed(() => {
      return minLogs.value - (fieldValue.value?.logs?.length || 0);
    });

    async function uploadLog(file) {
      try {
        isLoading.value = true;
        const data = {
          program_id: ctx.root.$route.params.programId || programDoc.value?.data?.program_id,
          public_id: programDoc.value?.data?._id,
          adk_name: props?.adkType,
          image: file.base64,
          log_name: state.logInput,
          file_name: file.name,
          file_type: file.type
        };

        const resp = await axios.post(
          `${process.env.VUE_APP_TEAM_SERVICE}/make/upload-image`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (resp.data.data) {
          updateProgramState(resp.data.data);
          props.updateDoc();
          // if (logsLeft.value === 0 || logsLeft.value < 0) ctx.emit('complete-adk');
          state.logInput = '';
          state.images = [];
          state.logError = '';
          await loadInitialData();
          state.logMilestoneLoading = false;
        }

        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
      }
    }

    async function updateLogs() {
      isLoading.value = true;
      try {
        const data = {
          adks: props.data.adks
        };
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isLoading.value = false;
      }
    }

    const logMilestone = async () => {
      if (!state.images.length && !state.logInput.length) {
        state.logError = 'Describe and attach an image to document your milestone';
        return;
      }
      if (!state.images.length) {
        state.logError = 'Attach milestone screenshot, image or photo';
        return;
      }
      if (!state.logInput.length) {
        state.logError = 'Describe your milestone';
        return;
      }
      state.logMilestoneLoading = true;

      // eslint-disable-next-line no-restricted-syntax
      for (const image of state.images) {
        const reader: any = new FileReader();
        reader.readAsDataURL(image);
        const payload: any = {
          name: image.name,
          type: image.type
        };
        reader.onload = async () => {
          const regex = /^data:image\/\w+;base64,/;
          payload.base64 = reader.result?.replace(regex, '');
          await uploadLog(payload);
        };
        reader.onerror = error => {
          console.log('Error: ', error);
        };
      }
    };

    const removeMilestone = async item => {
      try {
        isRemoving.value = true;
        const resp = await axios.delete(
          `${process.env.VUE_APP_TEAM_SERVICE}/make/remove-file?log_id=${item.id}&program_id=${
            ctx.root.$route.params.programId || programDoc.value?.data?.program_id
          }&public_id=${programDoc.value?.data?._id}&file_key=${
            item.proof[0].url.split('/')[3]
          }&adk_name=${props?.adkType}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (resp.data.data) {
          updateProgramState(resp.data.data);
          await loadInitialData();
          props.updateDoc();
          isRemoving.value = false;
        }
      } catch (error) {
        isRemoving.value = false;
      }
    };

    const removeFile = (file: string) => {
      state.images = state.images.filter((image: File) => image.name !== file);
    };

    const onFilesAdded = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files as FileList;
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        state.images.push(file);
      }
    };

    return {
      ...toRefs(state),
      adkData,
      fileInput,
      logMilestone,
      state,
      user,
      removeMilestone,
      removeFile,
      logsLeft,
      onFilesAdded,
      successMsg,
      errorMsg,
      msg,
      isRemoving,
      programDoc,
      fieldValue,
      updateLogs,
      logs
    };
  }
};
